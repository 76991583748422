import { AxiosResponse } from 'axios';
import http from '@/services/http.service';

class UserGroupService {
  public getGroups() {
    return http.get('/group').then(({ data }: AxiosResponse) => data.data);
  }
}

export default new UserGroupService();
