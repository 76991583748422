export class Utils {
  static generateID() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
  }

  static removeCharactersText(text: string) {
    return text ? text.replace(/[^0-9]+/g, '') : '';
  }

  static formatToCurrency(data: number) {
    if (!data) {
      throw new Error('Você não passou nenhum valor para ser formatado');
    }
    return data.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }
}
