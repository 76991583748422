import { GroupEnum } from '../enum/group.enum';

export class Group {
  public id: string;

  public name: string;

  public active: boolean;

  constructor(data: any = {}) {
    this.id = data.id || '';
    this.name = data.name || GroupEnum.PARTNER;
    this.active = data.active;
  }

  public get isSuperAdministrator() {
    return this.name === GroupEnum.SUPER_ADMINISTRATOR;
  }

  public get isPartner() {
    return this.name === GroupEnum.PARTNER;
  }

  public get isSite() {
    return this.name === GroupEnum.SITE;
  }
}
