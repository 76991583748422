import store from '@/store';
import { Utils } from '@/services/utils/utils.service';
import userService from '../services/user.service';
import { Group } from './group.entity';
import { Enterprise } from '../../Enterprises/entities/enterprise.entity';
import userGroupService from '../services/user-group.service';
import { GroupEnum } from '../enum/group.enum';
import { SellingPoint } from '../../SellingPoints/entities/selling-point.entity';
import { Status } from '../../Status/entities/status.entity';

export class User {
  public id: string = '';

  public name: string = '';

  public email: string = '';

  public phone: string = '';

  public active: number | boolean;

  public notification_by_email: boolean = false;

  public notification_by_sms: boolean = false;

  public has_relationship: boolean | number = false;

  public enterprise: Enterprise[] = [];

  public company_name: string;

  public selling_points: SellingPoint[] = [];

  public statuses: any[] = [];

  public group: Group;

  public roleId: string | undefined = '';

  public roleName: string | undefined = '';

  public companyId: string = '';

  public sellingPointId: string = '';

  public parents: string[] = [];

  public labelName: string = '';

  public secret: string = '';

  public api_integration: boolean = false;

  public avatarColor: string = '';

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.email = data.email;
    this.company_name = data.company_name;
    this.phone = data.phone;
    this.companyId = data.company && data.company.id ? data.company.id : store.getters.user.company_id;

    this.active = data.active === false ? 0 : 1;
    this.group = new Group(data.group);
    this.parents = data.parents || [];
    this.roleId = data.role ? data.role.id : undefined;
    this.roleName = data.role_name ? data.role_name : '-';
    this.enterprise = data.enterprise || [];
    this.selling_points = data.selling_point || [];
    this.statuses = data.statuses || [];
    this.notification_by_email = data.notification_by_email === true;
    this.notification_by_sms = data.notification_by_sms === true;
    this.labelName = this.initialsName();
    this.avatarColor = this.handleColors();
    this.has_relationship = data.has_relationship === true;
    this.secret = data.secret || '';
    this.api_integration = data.api_integration === true;
  }

  public get activeText() {
    return this.active === 1 ? 'Ativo' : 'Inativo';
  }

  public async save() {
    return userService.create(await this.format());
  }

  public async edit() {
    return userService.update(this.id, await this.format());
  }

  public delete() {
    return userService.delete(this.id);
  }

  public resetFieldsOnChangeGroup() {
    this.roleId = '';
    this.selling_points = [];
    this.enterprise = [];
  }

  public async format() {
    return {
      ...this,
      group_id: this.group.id ? this.group.id : await this.getGroupIdPartner(),
      role_id: this.roleId,
      company_id: this.companyId,
      statuses: this.statuses.map((it: Status) => it.id),
      parents:
        this.parents && this.parents.length ? this.parents.map((it: any) => it.id) : undefined,
      selling_points:
        this.selling_points && this.selling_points.length
          ? this.selling_points.map((it: any) => it.id)
          : undefined,
      enterprise:
        this.enterprise && this.enterprise.length
          ? this.enterprise.map((it: any) => it.id)
          : undefined,
      phone: Utils.removeCharactersText(this.phone)
        ? Utils.removeCharactersText(this.phone)
        : undefined,
    };
  }

  private async getGroupIdPartner() {
    const groupIds: Group[] = await userGroupService.getGroups();
    const partner = groupIds.find((it: Group) => it.name === GroupEnum.PARTNER);
    return partner ? partner.id : '';
  }

  private initialsName() {
    if (!this.name) return '';
    const isDoubleName = this.name.split(' ').length >= 2;
    if (isDoubleName) {
      const firstNameLetter = this.name.split(' ')[0][0];
      const lastNameLetter = this.name.split(' ')[1][0];
      return `${firstNameLetter}${lastNameLetter}`;
    }
    return `${this.name[0]}${this.name[1]}`;
  }

  private handleColors() {
    const colors = ['primary', 'blue-grey', 'teal', 'orange', 'indigo'];
    const indexRandom = Math.floor(Math.random() * 5) + 1;
    return colors[indexRandom];
  }
}
