
import { Component, Vue } from 'vue-property-decorator';
import ReminderCalendar from './reminder-calendar.component.vue';
import reminderService from './service/reminder.service';
import { ClientReminder } from './entities/client-reminders.entity';

@Component({
  components: {
    ReminderCalendar,
  },
})
export default class ClientRemindersComponent extends Vue {
  private reminders: ClientReminder[] = [];

  private async getReminders() {
    this.$loader.open();
    const { id } = this.$route.params;
    this.reminders = [];
    const reminders: any = await reminderService.getRemindersByOrderId(id).finally(() => {
      this.$loader.close();
    });
    this.reminders = reminders.map((it: any) => new ClientReminder(it));
  }

  private created() {
    this.getReminders();
  }
}
